import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import BestZantacLawyersWrapper from "@components/layouts/bestzantaclawyersWrapper";
import MainBanner from "@components/bestzantaclawyers/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const Lawsuits = dynamic(
    () => import("@components/bestzantaclawyers/lawsuits"),
);
const CancerTypes = dynamic(
    () => import("@components/bestzantaclawyers/cancerTypes"),
);
const Ranitidine = dynamic(
    () => import("@components/bestzantaclawyers/ranitidine"),
);
const ZantacReplacement = dynamic(
    () => import("@components/bestzantaclawyers/zantacReplacement"),
);
const SideEffects = dynamic(
    () => import("@components/bestzantaclawyers/sideEffects"),
);

export default function MedicalSoftwareHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestZantacLawyersWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Lawsuits />
            <CancerTypes />
            <SideEffects />
            <ZantacReplacement />
            <Ranitidine />
        </BestZantacLawyersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
